@import 'font/font.scss';

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

* {
  outline: none;
}

a {
  text-decoration: none;
}

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: Ubuntu, 'times new roman', times, roman, serif;
  min-height: 100%;
  background: linear-gradient(156.46deg, #07c0b8 0%, #00dad0 24.21%, #0272bd 100%);
  background-repeat: no-repeat;
  background-attachment: fixed;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
